import ls from 'local-storage';

export function getAuthToken() {
  return ls('keepreading-auth-token');
}

export function isAuthenticated() {
  return getAuthToken();
}

export function setAuthToken(token) {
  return ls('keepreading-auth-token', token);
}

export function setAuthSchool(schoolId) {
  return ls('keepreading-auth-school', schoolId);
}

export function clearAuthToken() {
  ls.remove('keepreading-auth-user');
  ls.remove('keepreading-auth-role');
  return ls.remove('keepreading-auth-token');
}

export function setAuthUser(user){
  return ls('keepreading-auth-user', user);
}

export function getAuthUser() {
  try {
    return JSON.parse(ls('keepreading-auth-user'));
  } catch (e) {
    return null;
  }
}

export function setAuthRole(role){
  return ls('keepreading-auth-role', role);
}

export function getAuthRole() {
  return ls('keepreading-auth-role');
}

export function setTeacher(teacher){
  return ls('keepreading-auth-teacher', teacher);
}

export function getTeacher() {
  return ls('keepreading-auth-teacher');
}