import React, { Component } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import axios from 'axios';
import { isAuthenticated } from 'utils/auth';
import config from 'src/config';
import { getAuthToken } from 'utils/auth';
import './App.scss';

axios.interceptors.request.use(
  (axiosConfig) => {
    axiosConfig.baseURL = config.apiUrl;
    const token = getAuthToken();

    if (token) {
      axiosConfig.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    // console.log(axiosConfig);
    return axiosConfig;
  },
  (error) => {
    // console.error(error);
    return Promise.reject(error);
  }
);

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !isAuthenticated()
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
);

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isAuthenticated()
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
);

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
// const Register = React.lazy(() => import('./views/Pages/Register'));
// const Confirm = React.lazy(() => import('./views/Pages/Confirm'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const ResetPassword = React.lazy(() => import('./views/Pages/ResetPassword'));
const forgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword'));

class App extends Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <UnauthenticatedRoute exact path="/login" name="Login Page" component={Login} />
            <UnauthenticatedRoute exact path="/forgot-password/:id" name="Reset Password" component={ResetPassword} />
            <UnauthenticatedRoute exact path="/reset-password" name="Reset Password" component={forgotPassword} />
            {/* <UnauthenticatedRoute exact path="/register" name="Register Page" component={Register} /> */}
            {/* <UnauthenticatedRoute exact path="/confirm" name="Confirm Page" component={Confirm} /> */}
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <AuthenticatedRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
